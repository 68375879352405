import { fileUploadPost } from '../Services/httpExtension';
import { get } from './../../Services/http';
import { catchError } from '../../Actions/Error.action';
import { receive as receiveCart } from './../../Actions/Cart.action';
import ExcelOrderContainer from '../Containers/ExcelOrder.container';
import { saveAs } from 'file-saver';

export const EXCEL_ORDER_IMPORT_STARTED = "EXCEL_ORDER_IMPORT_STARTED";
export const EXCEL_ORDER_IMPORT_SUCCESS = "EXCEL_ORDER_IMPORT_SUCCESS";
export const EXCEL_ORDER_IMPORT_ERROR = "EXCEL_ORDER_IMPORT_ERROR";

export const importExcel = (file) => (dispatch, getState) => {
    const data = new FormData();
    data.append('file', file);
    dispatch(importStarted())

    return fileUploadPost('/api/ExcelOrderImport/import', data)
        .then(response => response.json())
        .then(result => {
            window.location.href = getState().excelOrderImport.payload.checkoutUrl
        })
        .catch(ex => {
            ex.response.json().then(error => dispatch(importError(error)))
        })
}

export const downloadExample = () => (dispatch, getState) => {
    return get('/api/ExcelOrderImport/example')
        .then(response => response.blob())
        .then(blob => saveAs(blob, "example.xlsx"))
        .catch(ex => {            
            dispatch(downloadError('Example file is unavailable!'))
        })

}

export const importError = error => ({
    type: EXCEL_ORDER_IMPORT_ERROR,
    payload: {
        error: error.message,
        loading: false,
        uploadSuccess: false
    }
})

export const downloadError = message => ({
    type: EXCEL_ORDER_IMPORT_ERROR,
    payload: {
        error: message,
        loading: false,
        uploadSuccess: false
    }
})

export const importStarted = () => ({
    type: EXCEL_ORDER_IMPORT_STARTED,
    payload: {
        loading: true,
        uploadSuccess: false,
        error: null
    }
})

export const importFinished = () => ({
    type: EXCEL_ORDER_IMPORT_SUCCESS,
    payload: {
        loading: false,
        uploadSuccess: true,
        error: null
    }
})