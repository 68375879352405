import {
    EXCEL_ORDER_IMPORT_ERROR,
    EXCEL_ORDER_IMPORT_STARTED,
    EXCEL_ORDER_IMPORT_SUCCESS
} from '../Actions/ExcelOrderImport.action';

const DEFAULT_STATE = {
    error: null,
    loading: false,
    uploadSuccess: false
}

export const excelOrderImport = (state =DEFAULT_STATE, action)=>{
    switch(action.type){
        case EXCEL_ORDER_IMPORT_ERROR:
        case EXCEL_ORDER_IMPORT_STARTED:
        case EXCEL_ORDER_IMPORT_SUCCESS:
            return {
                ...state,
                ...action.payload
            }
            default:
                return state;
    }
}