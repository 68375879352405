import { fetch } from 'whatwg-fetch'

export const fileUploadPost = (url, file, abortController = null) =>  _fetch(url, "POST", file, abortController);


const _fetch = (url, method, data = null, abortController = null) => {
    let options = {
        method,
        headers: {
            'litium-request-context': JSON.stringify(window.__litium.requestContext),
        },
        credentials: 'include',
    };
    if (abortController) {
        options.signal = abortController.signal;
    }
    if (method !== "GET") {
        options = {
            ...options,
            body: data,
            headers: {
                ...options.headers
            }
        };
        
        const token = window.localStorage.getItem('requestVerificationToken');
        if (token) {
            options = {
                ...options,
                headers: {
                    ...options.headers,
                    'RequestVerificationToken': token,
                }
            }
        }
    }
    // use native browser implementation if it supports aborting
    const abortableFetch = ('signal' in new Request('')) ? window.fetch : fetch;
    return abortableFetch(url, options)
        .then(_checkStatus);
};

const _checkStatus = response => {
    if (response.status >= 200 && response.status < 300) {
        return response;
    } else {
        let error = new Error(response.statusText);
        error.response = response;
        throw error;
    }
};