import { get, put } from '../../Services/http';
import { catchError } from '../../Actions/Error.action';
import { toast } from "react-toastify";
import { translate } from '../../Services/translation';
import * as Enumerable from "linq-es5";

export const RETURN_ORDER_RECEIVE = 'RETURN_ORDER_RECEIVE'
export const SHOW_LOADER = 'SHOW_LOADER'
export const HIDE_LOADER = 'HIDE_LOADER'
export const RETURN_ORDER_SET_ID = 'RETURN_ORDER_SET_ID'
export const RETURN_ORDER_RECEIVE_REASON = 'RETURN_ORDER_RECEIVE_REASON'
export const REQUEST_STARTED = 'REQUEST_STARTED'
export const RETURN_ERROR = 'RETURN_ERROR'
export const RETURN_ORDER_SET_REFERENCE = 'RETURN_ORDER_SET_REFERENCE'
export const RETURN_ORDER_SET_RETURN_DATA = 'RETURN_ORDER_SET_RETURN_DATA'
export const RETURN_ORDER_SET_VALIDATION_STATUS = 'RETURN_ORDER_SET_VALIDATION_STATUS'
export const RETURN_ORDER_CLEAR_RETURN_ROWS = 'RETURN_ORDER_CLEAR_RETURN_ROWS'
export const SHOW_LOADER_RETURN = 'SHOW_LOADER_RETURN'

export const receiveOrderDetails = (orderId) => (dispatch, getState) => {
    return get(`/api/returnOrder/details/${orderId}`)
        .then(response => response.json())
        .then(result => dispatch(receive(result)))        
        .catch(ex => dispatch(catchError(ex, error => returnError(error))))
}

export const setCurrentOrderId = (orderId) => (dispatch, getState) => {
    dispatch(setOrderId(orderId));
}

export const setCurrentReference = (reference) => (dispatch) => {
    dispatch(setReference(reference));
}

export const removeReturnRow = (articleNumber) => (dispatch, getState) => {
    var currentRows = getState().returnOrder.returnRows;
    const newRows = currentRows.filter(row => {
        return row.articleNumber != articleNumber;
    });
    dispatch({
        type: RETURN_ORDER_SET_RETURN_DATA,
        payload: {
            returnRows: newRows
        }
    })
    var validationErrors = Enumerable.asEnumerable(newRows).Where(row => row.hasValidationErrors).Count();
    dispatch(setValidationErrorStatus(validationErrors > 0))
}

export const setReturnData = (data) => (dispatch, getState) => {
    var currentRows = getState().returnOrder.returnRows ? getState().returnOrder.returnRows : [];
    let found = false;
    const newRows = currentRows.map(row => {
        if (row.articleNumber === data.articleNumber) {
            found = true;
            return data;
        } else {
            return row;
        }
    });
    if (newRows.length == 0 || !found)
        newRows.push(data);

    dispatch({
        type: RETURN_ORDER_SET_RETURN_DATA,
        payload: {
            returnRows: newRows
        }
    })
    var validationErrors = Enumerable.asEnumerable(newRows).Where(row => row.hasValidationErrors).Count();
    dispatch(setValidationErrorStatus(validationErrors > 0))
}

export const displayValidationError = () => (dispatch) => {
    toast.error(translate('guidegloves.returnorder.validationerror'))
    dispatch(hideLoader())
}

export const sendReturn = () => (dispatch, getState) => {
    dispatch(sendingReturn(true))
    dispatch(showLoader())
    const data = getState().returnOrder.returnRows;
    return put('/api/returnOrder/return', data)
        .then(response => response.json())
        .then(result => {
            dispatch(hideLoader())
            dispatch(sendingReturn(false))
            toast.success(translate('returnorder.success'))
            dispatch(clearReturnRows())
        })
        .catch(ex => {
            dispatch(hideLoader())
            dispatch(sendingReturn(false))
            dispatch(catchError(ex, error => returnError(error)))
            toast.error(translate('returnorder.failed'))
        })
}

export const clearReturnRows = () => (dispatch) => {
    dispatch({
        type: RETURN_ORDER_CLEAR_RETURN_ROWS,
        payload: {
            returnRows: []
        }
    })
}

export const sendingReturn = (value) => (dispatch, getState) => {
    dispatch({
        type: SHOW_LOADER_RETURN,
        payload: {
            sendingReturn: value
        }
    })
}

export const showLoader = () => (dispatch, getState) => {
    dispatch({
        type: SHOW_LOADER,
        payload: {
            loading: true
        }
    })
}

export const hideLoader = () => (dispatch, getState) => {
    dispatch({
        type: HIDE_LOADER,
        payload: {
            loading: false
        }
    });
}

export const setValidationErrorStatus = status => ({
    type: RETURN_ORDER_SET_VALIDATION_STATUS,
    payload: {
        hasValidationErrors: status
    }
})

const setOrderId = orderId => ({
    type: RETURN_ORDER_SET_ID,
    payload: {
        orderId: orderId
    }
})

const setReference = reference => ({
    type: RETURN_ORDER_SET_REFERENCE,
    payload: {
        reference: reference
    }
})

const receive = result => ({
    type: RETURN_ORDER_RECEIVE,
    payload: {
        orderId: result.orderId,
        orderedArticles: result.orderedArticles,
        orderDate: result.orderDate,
        customerOrderNumber: result.customerOrderNumber,
    }
})

export const receiveReturnReason = reason => ({
    type: RETURN_ORDER_RECEIVE_REASON,
    payload: {
        returnReason: reason
    }
})

export const returnError = error => ({
    type: RETURN_ERROR,
    payload: {
        error,
    }
})

