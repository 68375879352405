import React from 'react';
import { connect } from 'react-redux';
import Navigation from '../Components/NavigationExtension';

const NavigationContainer = props => (
        <Navigation {...props} />
)

const mapStateToProps = state => {
    return {
        links: state.navigation.contentLinks,
        myPagesLink: state.navigation.myPagesLink
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavigationContainer);