import React, { Fragment } from 'react';
import LanguageSelectorContainer from '../Containers/LanguageSelectorMobile.container';


const Navigation = ({ myPagesLink, links = [], contentLink = null, traversedTimes = 1 }) => {
    let menuRef = React.createRef();
    let overlayRef = React.createRef();

    const setActiveMenu = (closeMenu) => {
        const menus = document.querySelectorAll(".navbar__menu--show");
        for (var i = 0; i < menus.length; i++) {
            menus[i].scrollTop = 0;
            menus[i].classList.remove("active");
        }

        if (!closeMenu) {
            menuRef.current.classList.add('active');
        }
        else {
            if (menuRef.current.closest(".navbar__menu--show")) {
                menuRef.current.closest(".navbar__menu--show").classList.add('active');
            }
            
        }
    };

    const toggleMenu = (closeMenu = false) => {
        menuRef.current.classList.toggle('navbar__menu--show');

        if (menuRef.current.classList.contains('navbar__menu--level-1')) {
            document.querySelector(".js-body").classList.toggle('scroll-disabled');
            overlayRef.current.classList.toggle('navbar__overlay--show');
        }
        setActiveMenu(closeMenu); //Fix for accelerator bug with visible parentmenues on scroll
    }

    const additionClass = contentLink && contentLink.attributes ? contentLink.attributes.cssValue : null;
    const hasChildrenClass = links.length > 0 ? 'has-children' : null;
    const isFirstLevel = traversedTimes === 1 ? true : false;
    const isSecondLevel = traversedTimes === 2 ? true : false;
    const showItemWithIcon = contentLink && contentLink.showWithIcons ? "navbar__menu navbar__menu--with-icons" : "";

    const ConditionalWrapper = ({ condition, wrapper, children }) =>
        condition ? wrapper(children) : children;

    return (
        <Fragment>
            {!contentLink ? (
                <Fragment>
                    <a className="navbar__link--block navbar__icon--menu navbar__icon show-for-medium-down" onClick={toggleMenu}>
                        <svg className="icon">
                            <use xlinkHref="/ui/images/Icons/icons.svg#menu"></use>
                        </svg>
                    </a>
                    <div className="navbar__overlay" ref={overlayRef} onClick={toggleMenu}></div>
                </Fragment>
            ) : (
                    <Fragment>
                        <a className={`navbar__link ${hasChildrenClass || ''} ${additionClass || ''}`}
                            href={contentLink.url || '#'} dangerouslySetInnerHTML={{ __html: contentLink.name }} >
                        </a>
                        {links.length > 0 &&
                            <Fragment>
                                <i className="navbar__icon--caret-right navbar__icon navbar__icon--open" onClick={toggleMenu}>
                                    <svg className="icon">
                                        <use xlinkHref="/ui/images/Icons/icons.svg#chevron-right-small"></use>
                                    </svg>
                                </i>
                                {isSecondLevel &&
                                    <i className="show-for-large navbar__icon-chevron">
                                        <svg className="icon">
                                            <use xlinkHref="/ui/images/Icons/icons.svg#chevron-down-small"></use>
                                        </svg>
                                    </i>
                                }
                            </Fragment>
                        }
                    </Fragment>
                )}

            {links.length > 0 &&
                <ConditionalWrapper
                    condition={isSecondLevel}
                    wrapper={children => <div className="navbar__menu-wrapper">{children}</div>}
                >
                    <ul className={`navbar__menu  navbar__menu--level-${traversedTimes} ${showItemWithIcon}`} ref={menuRef} test={links.length}>
                        <div className="navbar__menu-header">
                            {!contentLink ? (
                            <span className="navbar__icon navbar__icon--close" onClick={toggleMenu}>
                                    <svg className="icon">
                                        <use xlinkHref="/ui/images/Icons/icons.svg#x"></use>
                                    </svg>
                                </span>
                            ) : (
                                    <Fragment>
                                    <i className="navbar__icon--caret-left navbar__icon" onClick={(() => toggleMenu(true))}>
                                            <svg className="icon">
                                                <use xlinkHref="/ui/images/Icons/icons.svg#chevron-left-small"></use>
                                            </svg>
                                        </i>
                                    <span className="navbar__title" onClick={(() => toggleMenu(true))} dangerouslySetInnerHTML={{ __html: contentLink.name }}></span>
                                    </Fragment>
                                )}
                        </div>
                        
                        {contentLink && contentLink.showWithIcons ? (
                                
                            <Fragment>
                                {contentLink.rightColumnLinks.length > 0 && contentLink.rightColumnLinks.map((link, i) => !link.image ? null :
                                    <li className="navbar__item" key={`${link.name}-${i}`}>
                                        <a className="navbar__link" href={link.href}>
                                            <img alt={link.text} src={link.image.filename} className="navbar__image" />
                                            <span>{link.text}</span>
                                        </a>
                                    </li>
                                )}
                            </Fragment>

                        ) : (
                                <Fragment>
                                {links.length > 0 && links.map((link, i) => !link.name ? null :
                                    <li className="navbar__item" key={`${link.name}-${i}`}>
                                        <Navigation links={link.links} contentLink={link} traversedTimes={traversedTimes + 1} />
                                    </li>
                                )}
                                {isSecondLevel && contentLink.leftColumnTitle &&
                                    <li className="navbar__item navbar__category-item">


                                        {contentLink.leftColumnTitleLink ? (
                                            <a href={contentLink.leftColumnTitleLink.href} className="navbar__category-header">{contentLink.leftColumnTitle}</a>
                                        ):(
                                            <span className="navbar__category-header">{contentLink.leftColumnTitle}</span>
                                        )}

                                        <ul>{contentLink.leftColumnLinks.length > 0 && contentLink.leftColumnLinks.map((link, i) => !link.text ? null :
                                            <li className="navbar__item" key={`${link.name}-${i}`}>
                                                <a className="navbar__link" href={link.href}>{link.text}</a>
                                            </li>
                                        )}
                                        </ul>
                                    </li>
                                }
                                {isSecondLevel && contentLink.leftCenterColumnTitle &&
                                    <li className="navbar__item navbar__category-item">

                                        {contentLink.leftCenterColumnTitleLink ? (
                                            <a href={contentLink.leftCenterColumnTitleLink.href} className="navbar__category-header">{contentLink.leftCenterColumnTitle}</a>
                                        ):(
                                            <span className="navbar__category-header">{contentLink.leftCenterColumnTitle}</span>
                                        )}

                                        <ul>{contentLink.leftCenterColumnLinks.length > 0 && contentLink.leftCenterColumnLinks.map((link, i) => !link.text ? null :
                                            <li className="navbar__item" key={`${link.name}-${i}`}>
                                                <a className="navbar__link" href={link.href}>{link.text}</a>
                                            </li>
                                        )}
                                        </ul>
                                    </li>
                                }
                                {isSecondLevel && contentLink.rightCenterColumnTitle &&
                                    <li className="navbar__item navbar__category-item">
                                        {contentLink.rightCenterColumnTitleLink ? (
                                            <a href={contentLink.rightCenterColumnTitleLink.href} className="navbar__category-header">{contentLink.rightCenterColumnTitle}</a>
                                        ):(
                                            <span className="navbar__category-header">{contentLink.rightCenterColumnTitle}</span>
                                        )}
                                        <ul>{contentLink.rightCenterColumnLinks.length > 0 && contentLink.rightCenterColumnLinks.map((link, i) => !link.text ? null :
                                            <li className="navbar__item" key={`${link.name}-${i}`}>
                                                <a className="navbar__link" href={link.href}>{link.text}</a>
                                            </li>
                                        )}
                                        </ul>
                                    </li>
                                }
                                {isSecondLevel && contentLink.rightColumnTitle &&
                                    <li className="navbar__item navbar__category-item navbar__category-item--with-border">
                                        {contentLink.rightColumnTitleLink ? (
                                            <a href={contentLink.rightColumnTitleLink.href} className="navbar__category-header">{contentLink.rightColumnTitle}</a>
                                        ):(
                                            <span className="navbar__category-header">{contentLink.rightColumnTitle}</span>
                                        )}
                                        <ul>{contentLink.rightColumnLinks.length > 0 && contentLink.rightColumnLinks.map((link, i) => !link.text ? null :
                                            <li className="navbar__item" key={`${link.name}-${i}`}>
                                                <a className="navbar__link" href={link.href}>{link.text}</a>
                                            </li>
                                        )}
                                        </ul>
                                    </li>
                                }

                                    {isFirstLevel &&
                                        <Fragment>
                                            <li className="navbar__item  show-for-small-only">
                                                <a href={myPagesLink.href} className="navbar__link navbar__link--profile">
                                                    <svg className="icon">
                                                        <use xlinkHref="/ui/images/Icons/icons.svg#profile"></use>
                                                    </svg>
                                                    <span>{myPagesLink.text}</span>
                                                </a>
                                            </li>
                                            <li className="navbar__item show-for-small-only">
                                                <LanguageSelectorContainer />
                                            </li>
                                        </Fragment>
                                    }
                                </Fragment>
                        )}

                    {isSecondLevel && contentLink.buttonLink &&
                        <li className="navbar__bottom">
                            <b> {contentLink.footerText} </b>
                            <a href={contentLink.buttonLink.href} type="button" className="button button--transparent button--small">{contentLink.buttonText}</a>
                        </li>
                    }

                    </ul>


                </ConditionalWrapper>
            }
        </Fragment >
    )
}

export default Navigation;