import { post } from '../../Services/http';
import { load as loadCart } from '../../Actions/Cart.action';
import {
    submitDone,
    submitError,
    CHECKOUT_SET_CUSTOMER_INFO,
} from '../../Actions/Checkout.action'

export const CHECKOUT_SET_NOTE_OF_GOODS = 'CHECKOUT_SET_NOTE_OF_GOODS';
export const CHECKOUT_SET_CUSTOMER_ORDER_NUMBER = 'CHECKOUT_SET_RETAILER_ORDER_NUMBER';

export const submit = () => (dispatch, getState) => {
    const { payload } = getState().checkout;
    return _submit('/api/checkoutextensions/PlaceOrder', payload, dispatch);
}

export const setTemporaryAddress = (propName, value) => ({
    type: CHECKOUT_SET_CUSTOMER_INFO,
    payload: {
        key: 'temporaryAddress',
        data: {
            [propName]: value,
        }
    }
})

export const setNoteOfGoods = (noteOfGoods) => ({
    type: CHECKOUT_SET_NOTE_OF_GOODS,
    payload: {
        noteOfGoods: noteOfGoods
    }
})

export const setCustomerOrderNumber = (customerOrderNumber) => ({
    type: CHECKOUT_SET_CUSTOMER_ORDER_NUMBER,
    payload: {
        customerOrderNumber: customerOrderNumber
    }
});

const _submit = (url, model, dispatch) => {
    return post(url, model)
        .then(response => response.json())
        .then(result => {
            dispatch(submitDone(result));
        })
        .catch(ex => {
            if (ex.response) {
                ex.response.json().then(error => {
                    dispatch(submitError(error));
                    dispatch(submitDone(null));
                    // reload the cart, it might be changed after validation
                    dispatch(loadCart());
                });
            } else {
                dispatch(submitError(ex));
            }
        });
}