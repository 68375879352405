export const UPDATE_SELECTED_GTIN = "UPDATE_SELECTED_GTIN";

export const handleToggle = (id, index) => (dispatch) => {
    dispatch(toggle(id, index))
}

const toggle = (id, index) => ({
    type: UPDATE_SELECTED_GTIN,
    payload: {
        selectedGtin: id,
        index: index
    }
})