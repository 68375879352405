import {
    RETURN_ORDER_RECEIVE,
    REQUEST_STARTED,
    RETURN_ORDER_SET_ID,
    SHOW_LOADER,
    HIDE_LOADER,
    RETURN_ORDER_RECEIVE_REASON,
    RETURN_ERROR,
    RETURN_ORDER_SET_REFERENCE,
    RETURN_ORDER_SET_RETURN_DATA,
    RETURN_ORDER_SET_VALIDATION_STATUS,
    RETURN_ORDER_CLEAR_RETURN_ROWS,
    SHOW_LOADER_RETURN
} from '../Actions/ReturnOrder.action'


const DEFAULT_STATE = {
    orderId: '',
    orderedArticles: [],
    loading: false,
    returnReason: [],
    reference: '',
    returnInfo:'',
    returnRows: [],
    hasValidationErrors: false,
    sendingReturn: false,
}

export const returnOrder = (state = DEFAULT_STATE, action) => {

    switch (action.type) {
        case RETURN_ORDER_RECEIVE:
        case REQUEST_STARTED:
        case RETURN_ORDER_SET_ID:
        case SHOW_LOADER:
        case HIDE_LOADER:
        case RETURN_ORDER_RECEIVE_REASON:
        case RETURN_ERROR:
        case RETURN_ORDER_SET_REFERENCE:
        case RETURN_ORDER_SET_RETURN_DATA:
        case RETURN_ORDER_SET_VALIDATION_STATUS:
        case RETURN_ORDER_CLEAR_RETURN_ROWS:
        case SHOW_LOADER_RETURN:
            return {
                ...state,
                ...action.payload
            }            
        default:
            return state;
    }
}
