import React, { Component, Fragment } from 'react';
import parse from 'html-react-parser'

class InfoBoxGlobal extends Component {
	constructor(props) {
		super(props);
	}

    render() {
		const infoBox = this.props.infoBox.payload.infoBoxes.filter(box => box.infoBoxNotificationType === "global")[0];
		
		return (
			<Fragment>
				{infoBox && (
					<div className="info-box top-box">
						<div className="row">
							{parse(infoBox.infoBoxText)}
						</div>
					</div>
				)}
			</Fragment>
		);
	}
}

export default InfoBoxGlobal;