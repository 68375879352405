export const PACKAGE_TOGGLE = "PACKAGE_TOGGLE";

export const handleToggle = (erpArticleNumber, operator, nextOperator, elementId) => (dispatch) => {
    dispatch(toggle(erpArticleNumber, operator, nextOperator, elementId))
}

const toggle = (erpArticleNumber, operator, nextOperator, elementId) => ({
    type: PACKAGE_TOGGLE,
    payload: {
        operator,
        erpArticleNumber,
        nextOperator,
        elementId,
    }
})