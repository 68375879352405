import React, { Component } from 'react';
import ExcelOrderImport from '../Components/ExcelOrderImport'
import * as debounce from 'lodash.debounce';
import { connect } from 'react-redux';

import {importExcel, downloadExample} from '../Actions/ExcelOrderImport.action';

class ExcelOrderContainer extends Component{

    constructor(props){
        super(props);
        this.state={...props};
    }

    render(){
        return(<ExcelOrderImport {...{...this.props}}/>)
    }
}

const mapStateToProps = state =>
{
    return{
        error: state.excelOrderImport.error,
        loading: state.excelOrderImport.loading,
        uploadSuccess: state.excelOrderImport.uploadSuccess
    }
}

const mapDispatchToProps = dispatch => {
    return {
        importExcel: (file) =>{
            dispatch(importExcel(file));
        },
        downloadExample: ()=>{
            dispatch(downloadExample())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExcelOrderContainer);