import { INFO_RECIEVE } from '../Actions/InfoBox.action'

const defaultState = { 
    index: 0,
    title: "",
    text: "",
}

export const infoBox = (state = defaultState, action) => {
    
    const { type, payload } = action;
    switch (type) {
        case INFO_RECIEVE:
            return {
                ...state,
                ...payload
            }
        default:
            return state;
        
    }
}