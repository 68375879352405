import { combineReducers } from 'redux';
import { cart } from './Reducers/Cart.reducer';
import { quickSearch } from './Reducers/QuickSearch.reducer';
import { navigation } from './Reducers/Navigation.reducer';
import { facetedSearch } from "./Reducers/FacetedSearch.reducer";
import { myPage } from './Reducers/MyPage.reducer';
import { checkout } from "./Reducers/Checkout.reducer";
import { lightboxImages } from './Reducers/LightboxImages.reducer';
import { notification } from './Reducers/Notification.reducer';
import { packageToggle } from './GuideGloves/Reducers/PackageToggle.reducer';
import {returnOrder} from './GuideGloves/Reducers/ReturnOrder.reducer';
import { excelOrderImport } from './GuideGloves/Reducers/ExcelOrderImport.reducer';
import { languageSelector } from './GuideGloves/Reducers/LanguageSelector.reducer';
import { gtinList } from './GuideGloves/Reducers/GtinList.reducer';
import { externalOrders } from './GuideGloves/Reducers/ExternalOrders.reducer';
import { infoBox } from './GuideGloves/Reducers/InfoBox.reducer';

const app = combineReducers({
    cart,
    checkout,
    quickSearch,
    navigation,
    myPage,
    facetedSearch,
    lightboxImages,
    notification,
    packageToggle,
    returnOrder,
    excelOrderImport,
    languageSelector,
    gtinList,
    externalOrders,
    infoBox,
});

export default app;