import { UPDATE_SELECTED_GTIN } from "../Actions/GtinGroup.action";

const defaultState = {
    payload: []
}

export const gtinList = (state = defaultState, action) => {
    if (typeof action.payload !== 'undefined' && typeof action.payload.selectedGtin !== 'undefined') {
        var updateGtinGroups = state.payload.map((gtinGroup, index) => {
            if (action.payload.index === index) {
                return {
                    ...gtinGroup,
                    selectedGtin: action.payload.selectedGtin
                }
            }
            return gtinGroup;
        });
        var newPayload = { payload: updateGtinGroups };
    }

    switch (action.type) {
        case UPDATE_SELECTED_GTIN:
            return {
                ...newPayload
            }
        default:
            return state;
    }
}