import React, { Fragment } from 'react';

const LanguageSelector = ({ toggle, languages }) => (
    <div className="language-selector">
        {languages.payload.length > 0 &&
            <Fragment>
            <a className="language-selector__link--block" onClick={() => toggle()}>
                <i className="icon language-selector__icon--selected" >
                    <img className="language-selector__flag--selected language-selector__flag" loading="lazy" src={languages.payload.find(elm => elm.active).uri} alt={languages.payload.find(elm => elm.active).name} />
                </i>
            </a>
            <ul className={`language-selector__info ${!languages.showInfo ? 'language-selector__info--hidden' : ''}`}>
                {languages.payload.map((lang, i) => {
                    return (
                        <li key={i} className="language-selector__item">
                            <a href={lang.pageUrl} className="language-selector__link">
                                <i className="language-selector__icon">
                                    <img className="language-selector__flag" src={lang.uri} loading="lazy" alt={lang.name} />
                                </i>
                                <span className="language-selector__title">{lang.name}</span>
                            </a>
                        </li>
                    )
                })}
            </ul>
        </Fragment>
        }
    </div>
);

export default LanguageSelector;