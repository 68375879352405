import Headroom from "headroom.js";

const AddScrollFunctionalityForHeader = () => {
    var timeoutId 
    const headerHeight = document.querySelector(".js-header--headroom").offsetHeight;
    const header = document.querySelector(".js-header--headroom");
    var options = {
        offset: headerHeight,
        onTop: () => {
            clearTimeout(timeoutId)
            header.classList.remove("headroom--animate");
        },
        onNotTop: () => {
            clearTimeout(timeoutId)
            timeoutId = setTimeout(() => {
                header.classList.add("headroom--animate");
            }, 20);
        }
    };

    const headroom = new Headroom(header, options);

    headroom.init();
}


export default AddScrollFunctionalityForHeader;