import React, { Fragment } from 'react';
import { translate } from '../../Services/translation';

const sameCategory = (item, index, array) => index > 0 && array[index - 1].category === array[index].category || item.showAll;
let noHit = false;

const QuickSearchResult = ({ result, selectedItem, searchUrl }) => (
    <Fragment>
        <div className="quick-search-result">
            <ul className="quick-search-result__item-container">
                {result && result.map((item, index, array) => (
                    <Fragment key={`${item.name}-${index}`}>
                        {noHit = item.category === 'NoHit' ? true : false}
                        {item.category === 'NoHit' || sameCategory(item, index, array) ? null :
                            <li className="quick-search-result__item quick-search-result__group-header" >{item.category}</li>
                        }
                        <li className={`quick-search-result__item ${selectedItem === index ? 'quick-search-result__item--selected' : ''}`} >
                            <a className={item.showAll ? 'quick-search-result__show-all' : `quick-search-result__link ${item.url ? '' : 'quick-search-result__link--disabled'} ${item.category === 'NoHit' ? 'quick-search-result__no-hit' : ''}`}
                                href={item.showAll ? searchUrl : item.url}>
                                {item.hasImage && item.imageSource && <img className="quick-search-result__image" src={item.imageSource} />}
                                <div>{item.name}</div>
                            </a>
                        </li>
                    </Fragment>
                ))}
            </ul>
            { !noHit && <a href={searchUrl} className="quick-search-result__show-all button button--dark expanded">{translate('search.showall')}</a> }
            
        </div>
    </Fragment>
)

export default QuickSearchResult;