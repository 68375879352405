import React from 'react';
import { connect } from 'react-redux';
import LanguageSelector from '../Components/LanguageSelector';
import { toggle } from '../Actions/LanguageSelector.action';

const LanguageSelectorContainer = props => (
    <LanguageSelector {...props} />
)

const mapStateToProps = state => {
    return {
        languages: state.languageSelector,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        toggle: () => dispatch(toggle()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelectorContainer);