import React, { Component } from 'react';
import QuickSearchResult from './QuickSearchResultExtension';
import { translate } from '../../Services/translation';

class QuickSearch extends Component {
    focusOnInput() {
        setTimeout(() => {
            this.searchInput && this.searchInput.focus();
        }, 0);
    }

    render() {
        const { query, result, showResult, showFullForm, onSearch, onBlur, onKeyDown, toggleShowFullForm, selectedItem, searchUrl } = this.props;
        return (
            <div className="quick-search" role="search">

                <a className="quick-search__link--block" onClick={e => { toggleShowFullForm(); this.focusOnInput() }}>
                    <i className="quick-search__icon">
                        <svg className="icon">
                            <use xlinkHref="/ui/images/Icons/icons.svg#search"></use>
                        </svg>
                    </i>
                </a>
                <div className={`quick-search__overlay ${showFullForm ? 'quick-search__overlay--show' : ''}`}></div>
                <div className={`quick-search__form ${showFullForm ? 'quick-search__form--force-show' : ''}`} role="search">
                    <div className="quick-search__input-container">
                        <input className="quick-search__input" type="search" placeholder={translate('general.search')}
                            autoComplete="off" value={query} onChange={event => onSearch(event.target.value)}
                            onKeyDown={event => onKeyDown(event, { searchUrl })} ref={(input) => { this.searchInput = input; }}
                            onBlur={() => onBlur()} />
                        <button className="quick-search__submit-button" type="submit">
                            <i className="quick-search__submit-icon">
                                <svg className="icon">
                                    <use xlinkHref="/ui/images/Icons/icons.svg#search"></use>
                                </svg>
                            </i>
                        </button>
                        {showResult && <QuickSearchResult result={result} selectedItem={selectedItem} searchUrl={searchUrl} />}
                    </div>
                </div>
            </div>
        )
    }
}

export default QuickSearch;