import { get } from '../../Services/http';
import { catchError } from '../../Actions/Error.action';

export const EXTERNAL_ORDERS_FETCH = 'EXTERNAL_ORDERS_FETCH'
export const EXTERNAL_ORDERS_RECEIVE = 'EXTERNAL_ORDERS_RECEIVE'
export const EXTERNAL_ORDERS_ERROR = 'EXTERNAL_ORDERS_ERROR'
export const EXTERNAL_ORDERS_SET_DATE = 'EXTERNAL_ORDERS_SET_DATE'
export const EXTERNAL_ORDERS_SET_PAGE = 'EXTERNAL_ORDERS_SET_PAGE'
export const EXTERNAL_ORDER_FETCH_DETAIL = 'EXTERNAL_ORDER_FETCH_DETAIL'
export const EXTERNAL_ORDER_RECIEVE_DETAIL = 'EXTERNAL_ORDER_RECIEVE_DETAIL'

export const receiveExternalOrderDetails = () => (dispatch, getState) => {
    dispatch({
         type: EXTERNAL_ORDERS_FETCH, 
         data: {
              loading: true
         }
    });
    let state = getState().externalOrders.data;
    const company = typeof state.company === 'undefined' ? document.querySelector('#cid').value : state.company;
    return get(`/api/externalorders/?to=${state.to}&from=${state.from}&c=${company}`)
    .then(response => response.json())
    .then(result => dispatch(receive(result)))        
    .catch(ex => dispatch(catchError(ex, error => externalOrdersError(error))))
}

export const loadOrderDetail = (url, externalOrderId) => (dispatch) => {
    dispatch({
         type: EXTERNAL_ORDER_FETCH_DETAIL, 
         data: {
              loadingOrderDetail: true
         }
    });
    fetch(url,
            {
                headers: {
                    Layout: 'bodyonly'
                }
            })
        .then(response => response.text())
        .then(html => {
                var orderDetail = Array();
                orderDetail[externalOrderId] = html;
                //const orderDetail = {[externalOrderId]: html}
                dispatch({ type: EXTERNAL_ORDER_RECIEVE_DETAIL, 
                data: { orderDetail, loadingOrderDetail: false } })
        })
}

const receive = result => ({
    type: EXTERNAL_ORDERS_RECEIVE,
    data: {
        ...result
    }
})

export const externalOrdersError = error => ({
    type: EXTERNAL_ORDERS_ERROR,
    data: {
        error,
    }
})

export const setDates = (to, from) => (dispatch) => {
    return dispatch({
        type: EXTERNAL_ORDERS_SET_DATE,
        data: {
            from: from,
            to: to
        }
    })
}

export const navigateToPage = pageInformation => ({
    type: EXTERNAL_ORDERS_SET_PAGE,
    data: {
        pageInformation
    }
})