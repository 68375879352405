export const LANGUAGE_SHOW_INFO = 'LANGUAGE_SHOW_INFO';
export const LANGUAGE_RECEIVE = 'LANGUAGE_RECEIVE';

export const toggle = () => (dispatch, getState) => {
    dispatch(show(!getState().languageSelector.showInfo));
}

const show = visible => ({
    type: LANGUAGE_SHOW_INFO,
    payload: {
        showInfo: visible,
    }
})

export const receive = languageSelector => ({
    type: LANGUAGE_RECEIVE,
    payload: languageSelector,
})