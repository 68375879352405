import React from 'react';

const LanguageSelector = () => (
    <div className="language-selector--mobile">
        <ul className="language-selector__items-container">
            {window.__litium.preloadState.languageSelector.payload.map((lang, i) => {
                return (
                    <li key={i} className="language-selector__item">
                        <a href={lang.pageUrl} className="language-selector-mobile__link">
                            <i className="language-selector__icon">
                                <img className="language-selector__flag" loading="lazy" src={lang.uri} />
                            </i>
                        </a>
                    </li>
                )
            })}
        </ul>
    </div>
);

export default LanguageSelector;