import React from 'react';
import { translate } from "../../Services/translation";



const FacetedSearchCompact = ({ facetFilters, visibleDropdownMenu, toggleVisibleDropdownMenu, onFacetChange, onSubmit }) => {

    let filterRef = React.createRef();
    let showFilterButtonText = translate('filter.showmorefilter');
    let hideFilterButtonText = translate('filter.showlessfilter');

    function showMoreFilter(e) {
        filterRef.current.classList.toggle('faceted-search__show-all-filter');

        if (e.target.innerHTML === showFilterButtonText) {
            e.target.innerHTML = hideFilterButtonText;
        }
        else {
            e.target.innerHTML = showFilterButtonText;
        }

    }
    return (
        <ul className="faceted-search faceted-search--compact row" ref={filterRef}>
            {facetFilters && facetFilters.map((group, groupIndex, array) => group.options && group.options.length > 0 && (
                <li className="columns small-12 medium-6 large-4 faceted-search__group" key={`${group.label}-${groupIndex}`}>
                    <h4 className="faceted-search__group-name">{group.label}</h4>
                    <div className={`faceted-search__group-header ${visibleDropdownMenu.includes(group.id) ? 'faceted-search__group-header--show-compact' : ''}`} role="group" onClick={event => toggleVisibleDropdownMenu(group)}>
                        <span>{group.selectedOptions.length === 0 ? translate('filter.noneselected') : (group.selectedOptions.length + " " + translate('filter.selected'))}</span>
                        <i className="chevron__icon">
                            <svg className="icon">
                                <use xlinkHref="/ui/images/Icons/icons.svg#chevron-down-small"></use>
                            </svg>
                        </i>
                    </div>
                    <div className="faceted-search__sublist">
                        <ul className="faceted-search__item-container">
                            {group.options && group.options.map((item, itemIndex, array) => (
                                <li key={`${item.label}-${itemIndex}`} className={`faceted-search__item ${item.quantity === 0 ? 'disabled' : ''}`} role="faceted-search-item ">
                                    <FacetedFilterCheckbox item={item} group={group} onFacetChange={onFacetChange} />
                                </li>
                            ))}
                        </ul>
                        <div className="faceted-search__item faceted-search__button" >
                            <button className="button button--dark button--medium expanded" onClick={event => { toggleVisibleDropdownMenu(group); onSubmit(facetFilters) }}>{translate('general.select')}</button>
                        </div>
                    </div>
                </li>
            ))}
            {facetFilters.length > 0 &&
                <li className="faceted-search__show-filter columns large-4" onClick={(e) => showMoreFilter(e)} >
                    {showFilterButtonText}
                </li>
            }
        </ul>
    )
}

const FacetedFilterCheckbox = ({ item, group, onFacetChange }) => (
    <label className="faceted-filter" >
        <input className="faceted-filter__input" type="checkbox" onChange={event => onFacetChange(group, item)} checked={group.selectedOptions.includes(item.id)} />
        <span className="faceted-filter__label">
            <span className="faceted-filter__checkbox">
                <i className="checkmark__icon">
                    <svg className="icon">
                        <use xlinkHref="/ui/images/Icons/icons.svg#tick"></use>
                    </svg>
                </i>
            </span>
            <span className="faceted-filter__label-container">
                {item.label}
                {!isNaN(item.quantity) && item.quantity > 0 &&
                    <span className="faceted-filter__quantity"> ({item.quantity})</span>

                }
            </span>
        </span>
    </label>
)

export default FacetedSearchCompact;
