import React from 'react';
import { translate } from '../../Services/translation';

const MiniCart = ({ quantity, orderTotal, checkoutUrl, showInfo, toggle }) => (
    <div className="cart cart--mini">
        <a className="cart__link--block" onClick={() => toggle()}>
            <i className="cart__icon">
                <svg className="icon">
                    <use xlinkHref="/ui/images/Icons/icons.svg#cart"></use>
                </svg>
                <span className="cart__quantity">{ quantity }</span>
            </i>
        </a>
        <div className={ `cart__info ${!showInfo ? 'cart__info--hidden' : ''}` }>
            <p className="cart__info-row">{ quantity } { translate('minicart.numberofproduct') }</p>
            <p className="cart__info-row">
                <b className="cart__total-label">{translate('minicart.total')} :</b>
                {orderTotal}
            </p>
            <a href={ checkoutUrl } className="cart__checkout-button">{ translate('minicart.checkout') }</a>
        </div>
    </div>
)

export default MiniCart;