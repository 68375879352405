import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import InfoBoxGlobal from '../Components/InfoBoxGlobal'
import InfoBoxSticky from '../Components/InfoBoxSticky'
import InfoBoxProduct from '../Components/InfoBoxProduct'
import { receiveInfoBox } from '../Actions/InfoBox.action'

const InfoBoxContainer = props => (
    <Fragment>

        {props.type === "global" && <InfoBoxGlobal {...props} />}
        {props.type === "sticky" && <InfoBoxSticky {...props} />}
        {props.type === "product" && <InfoBoxProduct {...props} />}
    </Fragment>
)

const mapStateToProps = state => {
    return {
        ...state,
    }
}

export default connect(mapStateToProps, null)(InfoBoxContainer);