import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';
import app from './reducers';
import { historyMiddleware } from './Middlewares/History.middleware';
import MiniCartContainer from './GuideGloves/Containers/MiniCartExtension.container';
import InfoBoxContainer from './GuideGloves/Containers/InfoBox.container';
import QuickSearchContainer from './GuideGloves/Containers/QuickSearchExtension.container';
import NavigationContainer from './GuideGloves/Containers/NavigationExtension.container';
import FacetedSearchContainer from './Containers/FacetedSearch.container';
import FacetedSearchCompactContainer from './GuideGloves/Containers/FacetedSearchCompactExtension.container';
import NotificationContainer from './Containers/Notification.container';
import DynamicComponent from './Components/DynamicComponent';
import LanguageSelectorContainer from './GuideGloves/Containers/LanguageSelector.container';
import svg4everybody from './GuideGloves/svg4everybody';
import AddScrollFunctionalityForHeader from './GuideGloves/headroom';
import LazyLoad from 'vanilla-lazyload';

window.__litium = window.__litium || {};
const preloadState = window.__litium.preloadState || {};
// use the parent page's store if possible, so the iframe will share the same store with the parent context.
// this to makes for example the Reorder button in My Page page to update the state and reload the mini cart
// when reorder action happens.
const store = window.__litium.store = window.__litium.store || (window.parent.__litium && window.parent.__litium.store)
    || createStore(app, preloadState, composeWithDevTools(applyMiddleware(thunk, historyMiddleware)));
window.__litium = {
    ...window.__litium,
    bootstrapComponents: () => {
        // bootstrap React components, in case the HTML response we receive from the server
        // has React components. ReactDOM.render performs only an update on previous rendered
        // components and only mutate the DOM as necessary to reflect latest React element.
        bootstrapComponents();
    },
    cache: {}, // for storing cache data for current request
};

const bootstrapComponents = () => {
    if (document.getElementById('externalOrderList')) {
        const ExternalOrderListContainer = DynamicComponent({
            loader: () => import('./GuideGLoves/Containers/ExternalOrderList.container')
        });
        ReactDOM.render(
            <Provider store={store}>
                <ExternalOrderListContainer />
            </Provider>,
            document.getElementById('externalOrderList')
        )
    }

    if (document.getElementById('return-order-details')) {
        const ReturnOrderContainer = DynamicComponent({
            loader: () => import('./GuideGloves/Containers/ReturnOrder.container')
        });
        ReactDOM.render(
            <Provider store={store}>
                <ReturnOrderContainer />
            </Provider>,
            document.getElementById('return-order-details')
        )
    }

    if (document.getElementById('excel-order')) {
        const ExcelOrderContainer = DynamicComponent({
            loader: () => import('./GuideGloves/Containers/ExcelOrder.container')
        });
        ReactDOM.render(
            <Provider store={store}>
                <ExcelOrderContainer />
            </Provider>,
            document.getElementById('excel-order')
        );
    }

if (document.getElementById('miniCart')) {
        ReactDOM.render(
            <Provider store={store}>
                <MiniCartContainer />
            </Provider>,
            document.getElementById('miniCart')
        );
    }

    if (document.getElementById('languageSelector')) {
        ReactDOM.render(
            <Provider store={store}>
                <LanguageSelectorContainer />
            </Provider>,
            document.getElementById('languageSelector')
        );
    }

    if (document.getElementById('quickSearch')) {
        ReactDOM.render(
            <Provider store={store}>
                <QuickSearchContainer />
            </Provider>,
            document.getElementById('quickSearch')
        );
    }

    if (document.getElementById('navbar')) {
        ReactDOM.render(
            <Provider store={store}>
                <NavigationContainer />
            </Provider>,
            document.getElementById('navbar')
        );
    }

    if (document.getElementById('facetedSearch')) {
        ReactDOM.render(
            <Provider store={store}>
                <FacetedSearchContainer />
            </Provider>,
            document.getElementById('facetedSearch')
        );
    }

    if (document.getElementById('facetedSearchCompact')) {
        ReactDOM.render(
            <Provider store={store}>
                <FacetedSearchCompactContainer />
            </Provider>,
            document.getElementById('facetedSearchCompact')
        );
    }

    if (document.getElementById('globalNotification')) {
        ReactDOM.render(
            <Provider store={store}>
                <NotificationContainer />
            </Provider>,
            document.getElementById('globalNotification')
        );
    }

    if (document.getElementById('myPagePersons')) {
        const PersonList = DynamicComponent({
            loader: () => import('./Containers/PersonList.container')
        });
        ReactDOM.render(
            <Provider store={store}>
                <PersonList />
            </Provider>,
            document.getElementById('myPagePersons')
        );
    }

    if (document.getElementById('myPageAddresses')) {
        const AddressList = DynamicComponent({
            loader: () => import('./Containers/AddressList.container')
        });
        ReactDOM.render(
            <Provider store={store}>
                <AddressList />
            </Provider>,
            document.getElementById('myPageAddresses')
        );
    }
    if (document.getElementById('checkout')) {
        const Checkout = DynamicComponent({
            loader: () => import('./GuideGloves/Containers/Checkout.container.extension')
        });
        ReactDOM.render(
            <Provider store={store}>
                <Checkout />
            </Provider>,
            document.getElementById('checkout')
        );
    }
    if (document.getElementById('lightBoxImages')) {
        const LightboxImages = DynamicComponent({
            loader: () => import('./GuideGloves/Containers/LightboxImages.container.Extension')
        });
        const rootElement = document.getElementById('lightBoxImages');
        const images = Array.from(rootElement.querySelectorAll('a')).map(img => ({ src: img.dataset.src }));
        const thumbnails = Array.from(rootElement.querySelectorAll('a img')).map(img => ({ src: img.src }));
        ReactDOM.render(
            <Provider store={store}>
                <LightboxImages images={images} thumbnails={thumbnails} />
            </Provider>,
            document.getElementById('lightBoxImages')
        );
    }


    if (document.getElementById('gtinList')) {
        const GtinList = DynamicComponent({
            loader: () => import('./GuideGloves/Containers/GtinGroup.container')
        });
        ReactDOM.render(
            <Provider store={store}>
                <GtinList />
            </Provider>,
            document.getElementById('gtinList')
        );
    }


    if (document.querySelectorAll('.slider').length > 0) {
        const Slider = DynamicComponent({
            loader: () => import('./Components/Slider')
        });
        Array.from(document.querySelectorAll('.slider')).forEach((slider, index) => {
            const values = [...slider.querySelectorAll('.slider__block')].map(block => {
                return ({
                    image: block.dataset.image,
                    url: block.dataset.url,
                    text: block.dataset.text,
                })
            });
            if (values.length > 0) {
                ReactDOM.render(
                    <Slider values={values} />,
                    slider
                );
            }
        });
    }

    if (document.querySelectorAll('buy-button').length > 0) {
        const BuyButton = DynamicComponent({
            loader: () => import('./GuideGloves/Containers/BuyButton.container.Extension')
        });
        Array.from(document.querySelectorAll('buy-button')).forEach((button) => {
            const { articleNumber, quantityFieldId, href, cssClass } = button.dataset;
            const label = button.innerText;
            ReactDOM.render(
                <Provider store={store}>
                    <BuyButton {...{ label, articleNumber, quantityFieldId, href, cssClass }} />
                </Provider>,
                button
            );
        });
    }

if (document.querySelectorAll('package-toggle').length > 0) {
        const PackageToggle = DynamicComponent({
            loader: () => import('./GuideGloves/Containers/PackageToggle.container')
        });
        Array.from(document.querySelectorAll('package-toggle')).forEach((gtinPackage) => {
            ReactDOM.render(
                <Provider store={store}>
                    <PackageToggle />
                </Provider>,
                gtinPackage
            )
        })
    }

    if (document.querySelectorAll('reorder-button').length > 0) {
        const ReorderButton = DynamicComponent({
            loader: () => import('./Containers/ReorderButton.Container')
        });
        Array.from(document.querySelectorAll('reorder-button')).forEach((button) => {
            const { cssClass, orderId, title } = button.dataset;
            const label = button.innerText;
            ReactDOM.render(
                <Provider store={store}>
                    <ReorderButton {...{ label, title, cssClass, orderId }} />
                </Provider>,
                button
            );
        });
    }
    if (document.querySelectorAll('.icon').length > 0) {
        svg4everybody();
    }

    if (document.querySelector(".js-header--headroom")) {

        AddScrollFunctionalityForHeader();

        const whenPageAtTopRemoveClass = () => {
            if (window.pageYOffset === 0) {
                header.classList.remove("headroom--pinned");
            }
        }
        window.onscroll = function () { whenPageAtTopRemoveClass() };
    }

    if (document.getElementById('infoBoxGlobal')) {
        ReactDOM.render(
			<Provider store={store}>
				<InfoBoxContainer type="global" />
			</Provider>,
	        document.getElementById('infoBoxGlobal')
		);
	}

    if (document.getElementById('infoBoxSticky')) {
        ReactDOM.render(
			<Provider store={store}>
				<InfoBoxContainer type="sticky"  />
			</Provider>,
	        document.getElementById('infoBoxSticky')
		);
	}

    if (document.getElementById('infoBoxProduct')) {
        ReactDOM.render(
			<Provider store={store}>
				<InfoBoxContainer type="product"  />
			</Provider>,
	        document.getElementById('infoBoxProduct')
		);
	}
}

bootstrapComponents();

if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('/service-worker.js');
    });
}

//if ('Notification' in window) {
//    if (document.getElementById('notification-prompt')) {
//        const Notificationprompt = DynamicComponent({
//            loader: () => import('./GuideGloves/Containers/Notificationprompt.container')
//        });

//        ReactDOM.render(
//            <Provider store={store}>
//                <Notificationprompt />
//            </Provider>,
//            document.getElementById('notification-prompt')
//    )}
//}

window.addEventListener('beforeinstallprompt', (event) => {
    event.preventDefault();
    window.deferredInstallPrompt = event;

    if (document.getElementById('install-prompt')) {
        const InstallPrompt = DynamicComponent({
            loader: () => import('./GuideGloves/Containers/Installprompt.container')
        });

        ReactDOM.render(
            <Provider store={store}>
                <InstallPrompt />
            </Provider>,
            document.getElementById('install-prompt')
    )}

    return false;
});

new LazyLoad({});