import React, { Component, Fragment } from 'react';
import parse from 'html-react-parser'

class InfoBoxSticky extends Component {
	constructor(props) {
		super(props);
		this.state = {
			infoBoxIsOpen: true
		}
	}

	InfoBoxCloseHandler() {
		this.setState({
			infoBoxIsOpen: false
		});
	}

    render() {
		const linksExists = false;
		const infoBox = this.props.infoBox.payload.infoBoxes.filter(box => box.infoBoxNotificationType === "sticky")[0];

        return (
			<Fragment>
				{infoBox && this.state.infoBoxIsOpen &&
					<div className="info-box sticky-box">
						<div className="info-box__text">
							{!linksExists &&
								<button className="info-box__close--simple" onClick={this.InfoBoxCloseHandler.bind(this)}>
									<i className="close__icon">
										<svg className="icon">
											<use xlinkHref="/ui/images/Icons/icons.svg#x"></use>
										</svg>
									</i>
								</button>
							}
							{infoBox.infoBoxTitle && <h2>{infoBox.infoBoxTitle}</h2>}
							{parse(infoBox.infoBoxText)}
						</div>

						{linksExists &&
							<div className="info-box__buttons">
								<a className="info-box__link" href="#">See offer</a>
								<a className="info-box__link" href="#">Do some</a>
								<button className="info-box__link info-box__close" onClick={this.InfoBoxCloseHandler.bind(this)}>Close</button>
							</div>
						}
					</div>
				}
			</Fragment>
		);
	}
}

export default InfoBoxSticky;