import React, { Component, Fragment } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { translate } from '../../Services/translation';

class ExcelOrderImport extends Component {

    constructor(props) {
        super(props);
        this.uploadInput = React.createRef();
    }

    resetValueForFileUploadToBeAbleToUploadTheSameFileTwiceInArow() {
        if (this.uploadInput.current) {
            this.uploadInput.current.value = null;
        }
    }

    diplayInfo (){
        if (this.props.error) {
            return (
                <div
                    className="excel-order__status--error">
                    {this.props.error}
                </div>
            )
        }

        if (this.props.uploadSuccess) {
            return (
                <div
                    className="excel-order__status--success">
                    {translate('excelorder.successfulupload')}
                </div>
            )
        }
        return null;
    }

    render() {
        const {
            importExcel,
            downloadExample,
            loading,
        } = this.props;

        this.resetValueForFileUploadToBeAbleToUploadTheSameFileTwiceInArow();

        return (
            <div>
                <LoadingOverlay
                    active={loading}
                    spinner
                    text={translate('excelorder.processing')}
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            background:'rgba(255,255,255,1)'
                        }),
                        spinner: (base) => ({
                            ...base,
                            '& svg circle': {
                                stroke: 'rgba(255, 36, 9, 1)'
                            }
                        }),
                        content: (base) => ({
                            ...base,
                            color: '#000'
                        })
                    }}
                >
                    <Fragment>
                        {this.diplayInfo()}
                        <h2>{translate('excelorder.title')}</h2>
                        <div
                            className="excel-order__file-upload">
                            <label
                                htmlFor="excelFileInput"
                                className="product__buy-button">{translate('excelorder.upload')}
                            </label>
                            <input
                                id="excelFileInput"
                                ref={this.uploadInput}
                                type="file"
                                className="show-for-sr"
                                onChange={(e) => { importExcel(e.target.files[0]) }}
                                accept=".xls,.xlsx" />
                        </div>
                        <a
                            className="excel-order__example"
                            onClick={() => { downloadExample() }}>
                            {translate('excelorder.downloadexample')}
                        </a>
                    </Fragment>
                </LoadingOverlay>
            </div>
        )
    }
}

export default ExcelOrderImport