import {
    EXTERNAL_ORDERS_RECEIVE,
    EXTERNAL_ORDERS_ERROR,
    EXTERNAL_ORDERS_SET_DATE,
    EXTERNAL_ORDERS_SET_PAGE,
    EXTERNAL_ORDERS_FETCH,
    EXTERNAL_ORDER_FETCH_DETAIL,
    EXTERNAL_ORDER_RECIEVE_DETAIL
} from '../Actions/ExternalOrder.action'

const to = new Date();
const from = new Date();
roundMinutes(to);
roundMinutes(from);
from.setMonth(from.getMonth() - 1);

function roundMinutes(date) {
    date.setHours(date.getHours() + Math.ceil(date.getMinutes()/60));
    date.setMinutes(0, 0, 0);
    return date;
}

const DEFAULT_STATE = {
    data: {
        from: from.toISOString(),
        to: to.toISOString(),
        language: 'en',
        loading: true,
        loadingOrderDetail: false,
        orderDetail: [],
        pagination : {
            pageSize: 10
        },
        orders: []
    }
}

export const externalOrders = (state = DEFAULT_STATE, action) => {
    const { type, data } = action;
    switch (type) {
        case EXTERNAL_ORDERS_RECEIVE:
        case EXTERNAL_ORDERS_ERROR:
        case EXTERNAL_ORDERS_SET_DATE:
        case EXTERNAL_ORDERS_FETCH:
        case EXTERNAL_ORDER_FETCH_DETAIL:
            return {
                ...state,
                data: {
                    ...state.data,
                    ...data,
                }
            } 
        case EXTERNAL_ORDER_RECIEVE_DETAIL:
            return {
                ...state,
                data: {
                    ...state.data,
                    orderDetail: {
                        ...state.data.orderDetail,
                        ...data.orderDetail
                    },
                    loadingOrderDetail: data.loadingOrderDetail
                }
            } 
        case EXTERNAL_ORDERS_SET_PAGE:
            return {
                ...state,
                data: {
                    ...state.data,
                    pagination: {
                        ...state.data.pagination,
                        ...data.pageInformation,
                    }
                }
            }

        default:
            return state;
    }
}