import { get } from '../../Services/http';
import { catchError } from '../../Actions/Error.action';

export const INFO_RECIEVE = "INFO_RECIEVE";

export const receive = result => {
    return {
        type: INFO_RECIEVE,
        payload: result
    }
}


