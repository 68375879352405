import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import FacetedSearchCompact from '../Components/FacetedSearchCompactExtension';
import { AccordionPanel, Accordion } from './AccordionExtension.container';
import { PRODUCT_VIEW_CACHED, updateFilterOption, query, submitSearchFacet, toggleVisibleDropdownMenu, searchFacetChange } from '../Actions/FacetedSearchExtension.action';
import SubNav from '../Components/SubNavCompactExtension';
import SortCriteriaCompact from '../../Components/SortCriteriaCompact';
import FilterTag from '../Components/FilterTagsExtension';
import { translate } from '../../Services/translation';

class FacetedSearchCompactContainer extends Component {
    constructor(props) {
        super(props);
        const {facetFilters} = props;
        this.state = {
            facetFilters,
        };
    }

    componentDidMount() {
        this.props.query(decodeURI(window.location.search.substr(1)) || '', false);
    }

    onFacetChange (filter, option) {
        this.setState((prevState, props) => {
            const { facetFilters } = this.state;
            const newFilters = updateFilterOption(facetFilters, filter, option);
            return {
                ...prevState,
                facetFilters: newFilters,
            };
        });
    }

    static getDerivedStateFromProps(nextProp, prevState) {
        if (nextProp.productsViewCachedId !== prevState.productsViewCachedId) {
            return {
                ...prevState,
                ...nextProp,
            }
        }
        return prevState;
    }

    onSearchResultDataChange(dom) {
        if ( [null, undefined].includes(dom) ) {
            return;
        }
        const container = document.createElement('div');
        container.innerHTML = dom;
        const existingResult = document.querySelector("#search-result");
        const tempResult = container.querySelector("#search-result");
        const existingFilter = existingResult.querySelector('#facetedSearchCompact');
        const tempFilter = tempResult.querySelector('#facetedSearchCompact');
        const replace = (node, newNode) => node.parentNode.replaceChild(newNode, node);
        // move existingFilter from existingResult to tempResult
        replace(tempFilter, existingFilter);
        // replace existingResult with tempResult ( newResult )
        replace(existingResult, tempResult);
        // bootstrap react components if any exists in the search result
        window.__litium.bootstrapComponents();
    }

    componentDidUpdate() {
        const productViewCached = window.__litium.cache ? window.__litium.cache[PRODUCT_VIEW_CACHED] || {} : {};
        if (!productViewCached.used) {
            productViewCached.used = true;
            const dom = productViewCached.productsView;
            dom && this.onSearchResultDataChange(dom);
        }
    }

    render() {
        
        const {
            subNavigation=[],
            sortCriteria=[],
            navigationTheme='',
            ...facetProps
        } = this.props;
        const { facetFilters } = this.state;
        const facetState = {
            ...facetProps,
            facetFilters,
            onFacetChange: (filter, option) => this.onFacetChange(filter, option),
        };
   
        const hidden = [subNavigation, facetFilters, sortCriteria].every(ele => !ele || ele.length === 0);
        return ( hidden ? null :
            <Fragment>
                <Accordion className='compact-filter hide-for-large'>
                    {subNavigation.links && subNavigation.links.length > 0 &&
                        <AccordionPanel header={translate('facet.header.categories')}>                       
                        <SubNav {...{subNavigation}}/>
                    </AccordionPanel>
                    }
                    {facetFilters && facetFilters.length > 0 &&
                    <AccordionPanel header={translate('facet.header.filter')}>
                        <FacetedSearchCompact {...facetState} />
                    </AccordionPanel>
                    }
                    {sortCriteria && sortCriteria.length > 0 &&
                    <AccordionPanel header={translate('facet.header.sortCriteria')}>
                        <SortCriteriaCompact {...{sortCriteria}}/>
                    </AccordionPanel>
                    }
                </Accordion>
                {navigationTheme==='category' && (
                    <div className='compact-filter category-theme show-for-large'>
                        <FacetedSearchCompact {...facetState}/>
                    </div>
                )}
                <FilterTag {...{ ...facetProps, navigationTheme }} />
            </Fragment>
        );
    }
}

const mapStateToProps = ({facetedSearch:{subNavigation, sortCriteria, facetFilters, visibleDropdownMenu, navigationTheme, productsViewCachedId}}) => {
    return {
        subNavigation,
        sortCriteria,
        facetFilters,
        visibleDropdownMenu,
        navigationTheme,
        productsViewCachedId,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        query: (q, withHtmlResult) => dispatch(query(q, withHtmlResult)),
        onSubmit: (allFilters) => dispatch(submitSearchFacet(allFilters)),
        toggleVisibleDropdownMenu: (group) => dispatch(toggleVisibleDropdownMenu(group)),
        searchFacetChange: (group, item) => dispatch(searchFacetChange(group, item)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FacetedSearchCompactContainer);