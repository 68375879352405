import {
    PACKAGE_TOGGLE
} from '../Actions/PackageToggle.action'

const DEFAULT_STATE = {
    erpArticleNumber: '',
    operator: '',
    nextOperator: '+',
    elementId: '',
}

export const packageToggle = (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case PACKAGE_TOGGLE:
            return {
                ...state,
                ...action.payload,
            }
        default:
            return state;
    }
}